.mouse-down {
    position: absolute;
    margin-top: -100px;
    width: 100%;
    z-index: 999999;
    opacity: .32;

    & > div {
        display: flex;
        justify-content: flex-end;
        margin-right: 24px;

        & > div {
            margin: none !important;
        }
    }
}

.logo-hero {
    width: 300px !important;
    margin: 0 auto;
}

// video {
//     position: fixed;
//     right: 0;
//     bottom: 0;
//     min-width: 100%;
//     min-height: 100%;
// }

@media (max-width: 768px) {
    .mouse-down {
        & > div {
            margin-right: 8px;
        }
    }
}