ul {
    & > li {
        list-style-type: none;

        &:first-child {
            font-weight: bold;
        }

        & .title {
            display: block;
            width: 100%;
        }
    }
}
