.loader-container {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(70, 82, 89, .9);
    z-index: 9999;
    backdrop-filter: blur(8px);
}

.spinner {
    width: 120px;
    height: 120px;
    background-size: cover;
    animation: spin 8s linear infinite;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}