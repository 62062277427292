.piatti-logo {
    position: absolute;
    transform: rotate(-4deg);
    width: 320px;
    left: -40px;
    z-index: 0;
    opacity: 1;
    opacity: .1;
}

@media (max-width: 768px) {
    .piatti-logo {
        opacity: .1;
    }
}