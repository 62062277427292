.footerContainer .col-sm-4 {
    z-index: 1;
    position: relative;
}

.footer-logo {
    position: absolute;
    top: -150px;
    right: 50px;
    transform: rotate(45deg);
    z-index: 0;
    opacity: 0.3;
    animation: spin 120s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 768px) {
    .footer-logo {
        opacity: 0.1;
    }
}
