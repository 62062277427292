.navbar .menu-icon {
    display: none;
    font-size: 1.5em;
    cursor: pointer;
}

.navbar .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar .nav-links li {
    padding: 0 15px;
}

.navbar .nav-links a {
    color: white;
    text-decoration: none;
}

.logo {
    height: 32px;
    margin-top: 8px;
}

@media (max-width: 768px) {
    .navbar .menu-icon {
        display: block;
        float: right;
        margin: 8px 20px;
        color: #fff;
    }

    .navbar .nav-links {
        display: none;
        position: absolute;
        right: 0;
        top: 60px;
        flex-direction: column;
        width: 100%;
        background-color: rgba(70, 82, 89, .9);
    }

    .navbar .nav-links.open {
        display: flex;
    }

    .navbar .nav-links li {
        text-align: center;
        padding: 10px;
    }

        .logo {
            margin-left: 16px;
        }
}